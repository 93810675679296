
.container-fluid {
    position: fixed;
}
.page-structure-navbar {
    // height: 4vh;
    // margin-bottom: 2vh;
    height: 50px;
    margin-bottom: 12px;
    min-height: 50px;
    
    &.mobile {
        margin-bottom: 0;
        border-bottom: 1px solid #9c9b9b;
        
        .logo {
            margin-top: 3px !important;
        }
    }
}

.page-structure-content {
    height: calc(100% - 62px);
    width: 100%;
    max-width: 100vw;
}

.page-structure-content.page-structure-content--admin {
    height: calc(100vh - 62px);
    // margin-top: 2vh;
}

.page-structure-footer {
    height: 50px;
}

// Remove inherited margin on footer
.page-structure-footer ul.row {
    margin-bottom: 0;
    width: 100%;
}

.edit-mode-actions__edit-overlay-master-view {
    margin-top: 62px !important;
}
.edit-mode-actions__edit-overlay-top {
    height: 62px !important;
}
@media (max-width: 1200px) {
    .edit-mode-actions__edit-overlay-master-view {
        width: 25% !important;
    }
}

#navbarDropdown {
    // max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;

    // show full name on hover
    // &:hover {
    //     white-space: normal;
    //     overflow: visible;
    //     font-size: 10px;
    // }
}