$SXGA:1280px;     // 1280 x 1024 Super-eXtended Graphics Array (SXGA)
$HD:1366px;       // 1366 x 768 High Definition (HD)
$HD_PLUS:1600px;  // 1600 x 900 High Definition Plus (HD+)
$FHD:1920px;      // 1920 x 1080 Full High Definition (FHD)
$WUXGA:1920px;    // 1920 x 1200 Wide Ultra Extended Graphics Array (WUXGA)
$QHD:2560px;      // 2560 x 1440 Quad High Definition (QHD)
$WQHD:3440px;     // 3440 x 1440 Wide Quad High Definition (WQHD)
$UHD:3840px;      // 3840 x 2160 4K or Ultra High Definition (UHD)
// t r b l
// screen resolution, for responsive display width
@mixin screenResolution ($breakpoint) {

    // computer screen resolutions
    @if $breakpoint == SXGA {
        @media only screen and (min-width: 1080px) and (max-width: $SXGA) { // USED
        @content;
        }
    }
    @if $breakpoint == HD {
        @media only screen and (max-width: 1236){
        @content;
        }
    }
    @if $breakpoint == HD_PLUS {
        @media only screen and (min-width: 1236px) and (max-width: $HD_PLUS) {
        @content;
        }
    }
    @if $breakpoint == FHD {
        @media only screen and (min-width: $HD_PLUS) and (max-width: $FHD) {
        @content;
        }
    }
    @if $breakpoint == WUXGA {
        @media only screen and (min-width: $HD_PLUS) and (max-width: $WUXGA) {
        @content;
        }
    }
    @if $breakpoint == QHD {
        @media only screen and (min-width: $FHD) and (max-width: $QHD) {
        @content;
        }
    }
    @if $breakpoint == WQHD {
        @media only screen and (min-width: $QHD) and (max-width: $WQHD) {
        @content;
        }
    }
    @if $breakpoint == UHD {
        @media only screen and (min-width: $UHD) {
        @content;
        }
    }

    // mobile resolution and others
    @if $breakpoint == MIN_SCREEN { // USED
        @media only screen and (min-width: 870px) and (max-width: 1079px) {
        @content;
        }
    }
    @if $breakpoint == MAX_SCREEN {
        @media only screen and (min-width: 1920px) {
        @content;
        }
    }

    @if $breakpoint == MOBILE_SCREENS {
        @media only screen and (max-width: 870px) {
        @content;
        }
    }

    @if $breakpoint == ANY_MINIMUM_SCREEN {
        @media only screen and (max-width: 1280px) {
        @content;
        }
    }
    // most laptop screens 
    @if $breakpoint == LAP_TOP_SCREENS_1 {
        @media only screen and (min-width: 870px) and (max-width: 1236px) {
        @content;
        }
    }
    @if $breakpoint == LAP_TOP_SCREENS_2 {
        @media only screen and (min-width: 1237px) and (max-width: 1920px) {
        @content;
        }
    }
    
    @if $breakpoint == TABLET_PORTRAIT_1 {
        @media only screen and (max-width: 780px) and (max-height: 1000px){
            @content;
            }
    }
    
    @if $breakpoint == TABLET_LANDSCAPE_1 {
        @media only screen and (min-width: 781px) and (max-width: 1044px) and (max-height: 800px) {
            @content;
            }
    }

}