$backgroundColor: rgb(47, 48, 51);
$primaryColor: rgb(248, 245, 244);

$secondaryBackgroundColor: rgb(47, 48, 51);
$secondaryColor: rgb(102, 102, 102);

$warningColor: rgb(169, 68, 66);

$card-cap-bg : rgb(46, 47, 51);



:root {
    --atmosphere-main-color: rgb(242, 166, 59);
    --atmosphere-save-button-color: rgb(242, 166, 59);
    --atmosphere-cancel-button-color: #2f3033;
    --atmosphere-delete-button-color: #92140C;
    --atmosphere-body-background: rgb(35, 35, 35);
    --atmosphere-input-color: #18191C;
    --atmosphere-master-view-1: rgb(54, 54, 54);
    --product-tab-hovering-color: rgb(242, 166, 59);
    --product-tab-color-color-one: #313237;
    --product-tab-color-color-two: #1D1E21;
    --product-tab-color-color-three: #2E3034;
    --product-tab-color-color-four: #2f3033;
    --atmosphere-progress-bar-thumb: #fff;
    --order-page-default-pixels: 10px;
    --atmosphere-border-radius: .55rem;
    --atmosphere-border-radius1: .725rem;
    --atmosphere-border-radius2: .375rem;

    //rgb(159, 226, 191) : Seafoam green
    //rgb(255, 160, 122) : Vivid tangerine


    // library mobile master view tabs
    --atmosphere-mobile-library-groups: #3f4043;
    --atmosphere-mobile-library-tab-selected-color: rgb(0, 0, 0);
    --atmosphere-mobile-library-nav: rgb(35, 35, 35);
    // cards
    --atmosphere-playlist-row-background: rgb(60, 60, 60);
    --atmosphere-audio-cards-background: rgb(60, 60, 60);
    --atmosphere-cards-background-2: rgb(60, 60, 60);
    --atmosphere-cards-background: rgb(60, 60, 60);
    --atmosphere-secondary-card-text: #c3c3c3;
    --atmosphere-image-cards-height: 103px;
    --atmosphere-mobile-icon-height: 80px;

    --atmosphere-cards-container-height: 130px;
    --atmosphere-audio-container-radius: 9px;
    --atmosphere-audio-container-padding: 2px;
    --atmosphere-audio-card-height: 138px;
    --atmosphere-audio-card-width: 126px;

    --atmosphere-audio-icon-height: 112px;
    --atmosphere-cards-width-big: 230px;
    --atmosphere-cards-icon-radius: 6px;
    --atmosphere-cards-icon-border: 2px;
    --atmosphere-cards-icon-padding: 2px;
    --atmosphere-cards-margin: 3px; // card margin
    --atmosphere-cards-selection-border: 2px;

    // navigation
    --navigation-background: rgb(35, 35, 35);

    // font family
    --atmosphere-cards-title-font-family: "Raleway", sans-serif !important;
}
